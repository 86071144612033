import {Col, DatePicker, Row} from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import {GAP_SIZE_2, GAP_SIZE_BASE} from '../../constants';
import TopMark from '../../svgs/top-mark';
import ArrowRight from '../../svgs/arrow-right';
import * as styles from './index.module.scss';
import {Link} from 'gatsby-plugin-react-i18next';

export type ViewModel = {
  articles: {
    id: string;
    isPinned: boolean;
    cover?: string;
    /** UTC */
    publishTimestamp: number;
    title: string;
    summary: string;
  }[];
  pagination: NewsPageContext['pagination'] & {
    pageSize: number;
  };
};

type PropTypes = {
  vm: ViewModel;
};

export default function LuArticleList({vm}: PropTypes) {
  const pagesArr = Array.from({length: vm.pagination.total}, (_, i) => i + 1);
  function onYearPickerChange(_: any, year: string) {
    const newPath = year ? `/news/${year}/page/1` : '/news/page/1';
    window.location.pathname = newPath;
  }
  return (
    <div className={styles.articleList}>
      {vm.articles.map(article => {
        const publishDate = dayjs(article.publishTimestamp);
        const dateString = publishDate.format('MM / DD');
        return (
          <div key={article.id} className={styles.articleContainer}>
            {/* 文章卡片 */}
            <Link to={`/news/${article.id}`}>
              <Row className={styles.article} justify="center">
                <Col className={styles.content} span={22}>
                  {article.cover && (
                    <img className={styles.cover} src={article.cover} alt="" />
                  )}
                  <div className={styles.smContainer}>
                    <Row
                      align="stretch"
                      gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
                      wrap={false}
                    >
                      <Col>
                        <div className={styles.year}>
                          {publishDate.year()} 年
                        </div>
                        <div className={styles.date}>{dateString}</div>
                      </Col>
                      <Col>
                        <div className={styles.divider} />
                      </Col>
                      <Col flex={1}>
                        <div className={styles.title}>{article.title}</div>
                        <div className={styles.summary}>{article.summary}</div>
                      </Col>
                    </Row>
                    <Row justify="end">
                      <Col className={styles.arrow}>
                        <ArrowRight />
                      </Col>
                    </Row>
                  </div>
                  <div className={styles.xsContainer}>
                    <div>{publishDate.format('YYYY.MM.DD')}</div>
                    <div>{article.title}</div>
                  </div>
                </Col>
                {/* 置顶 */}
                {article.isPinned && (
                  <div className={styles.isPinned}>
                    <TopMark />
                  </div>
                )}
              </Row>
            </Link>
          </div>
        );
      })}
      {/* 翻页器 */}
      <Row
        className={styles.pagination}
        gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
        align="middle"
        justify="center"
      >
        {pagesArr.map(pageIndex => {
          const link = vm.pagination.year
            ? `/news/${vm.pagination.year}/page/${pageIndex}`
            : `/news/page/${pageIndex}`;
          return (
            <Col key={pageIndex}>
              <Link to={link}>
                <div
                  className={clsx(
                    styles.paginationItem,
                    pageIndex === vm.pagination.current && styles.active
                  )}
                >
                  {pageIndex}
                </div>
              </Link>
            </Col>
          );
        })}
        <Col key="year">
          <DatePicker
            className={styles.datePicker}
            picker="year"
            placeholder="按年份筛选"
            suffixIcon={null}
            onChange={onYearPickerChange}
            defaultValue={
              vm.pagination.year ? dayjs(vm.pagination.year) : undefined
            }
            disabledDate={date =>
              !vm.pagination.availableYears.some(year =>
                date.isSame(`${year}-01-01`, 'year')
              )
            }
          />
        </Col>
      </Row>
    </div>
  );
}
