import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function TopMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.395"
      height="55.153"
      viewBox="0 0 39.395 55.153"
      style={svgStyle}
    >
      <g id="组_7" data-name="组 7" transform="translate(-475.822 -1420.57)">
        <path
          id="路径_4"
          data-name="路径 4"
          d="M515.216,1475.722l-19.7-9.046-19.7,9.046V1420.57h39.395Z"
          fill="#002063"
        />
        <path
          id="路径_5"
          data-name="路径 5"
          d="M495.519,1434.8l3.382,6.852,7.561,1.1-5.471,5.333,1.292,7.531-6.763-3.556-6.763,3.556,1.292-7.531-5.471-5.333,7.561-1.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
