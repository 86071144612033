import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import LuArticleHero from '../components/lu-article-hero';
import DefaultLayout from '../layouts/default';
import heroImage from '../images/news-hero.jpg';
import coverImage from '../images/article-mock-cover.png';
import LuArticleList, {
  ViewModel as LuArticleListViewModel,
} from '../components/lu-article-list';
import {useTranslation} from 'react-i18next';

const NewsListPage = ({
  location,
  pageContext,
}: PageProps<null, NewsPageContext>) => {
  console.log(pageContext);
  const currentLanguage = pageContext.i18n.language;
  const {t} = useTranslation('news');
  const PAGE_DATA = {
    title: 'Company News',
    heroImage: heroImage,
    subTitle: t('公司动态'),
  };
  const articleList: LuArticleListViewModel = {
    articles: pageContext.articles.map(article => {
      const translation = article.translations.filter(
        translation => translation.languages_code.code === currentLanguage
      )[0];
      const summary = translation?.summary || '';
      return {
        id: article.id,
        title: translation?.title,
        summary,
        isPinned: Boolean(article.is_pinned),
        publishTimestamp: new Date(article.date_created).valueOf(),
        cover: article.cover_image?.imageFile?.publicURL,
      };
    }),
    pagination: {
      pageSize: 10,
      ...pageContext.pagination,
    },
  };
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        bgImageUrl={PAGE_DATA.heroImage}
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
      />
      <LuArticleList vm={articleList} />
    </DefaultLayout>
  );
};

export default NewsListPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
